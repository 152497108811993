import { useEffect } from "react";
import { updateHash } from "@/utils/urlHash";
import {
  SandpackLayout,
  SandpackPreview,
  useActiveCode,
} from "@codesandbox/sandpack-react";
import { demoHtml } from "@/demoData";
import { useNavigate } from "react-router-dom";
import { SandpackMonacoEditor } from "@/components/editor/SandpackMonacoEditor";

function MockEditMessage() {
  const { code } = useActiveCode();
  const navigate = useNavigate();

  useEffect(() => {
    if (code != demoHtml) {
      updateHash(code);
    }
  }, [code]);

  function handleMockGenerate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const prompt = event.target["prompt"].value;
    const urlSafePrompt = encodeURIComponent(prompt);
    navigate(`/signup?prompt=${urlSafePrompt}`);
  }

  return (
    <>
      <div className="border-2 rounded-lg h-fit mb-4">
        {/* This is needed to keep the editor buttons aligned */}
        <div className="absolute right-6 mt-1 mr-3 z-40"></div>

        <SandpackLayout>
          <SandpackPreview
            style={{
              height: "50vh",
            }}
            showOpenInCodeSandbox={false}
            showRefreshButton={false}
          />

          <SandpackMonacoEditor
            style={{
              height: "50vh",
            }}
          />
        </SandpackLayout>
      </div>

      <div className="flex flex-row">
        <div className="w-full max-w-2xl mx-auto">
          <form
            className="flex max-w-2xl gap-x-4"
            onSubmit={handleMockGenerate}
          >
            <input
              id="prompt"
              name="prompt"
              type="text"
              className="font-mono min-w-0 flex-auto rounded-xl border border-neutral-300 bg-white/5 px-3.5 py-2 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-inset focus:ring-gray-100 text-sm md:text-base lg:text-base sm:leading-6 disabled:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
              placeholder="Generate HTML with AI"
            />
            <button
              type="submit"
              className="font-mono flex-none rounded-xl text-white px-3.5 py-2.5 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:opacity-75 w-22 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-red-500 hover:to-orange-400"
            >
              Generate
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export { MockEditMessage };
