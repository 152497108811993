import { useEffect, useState, useContext } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import supabase from "@/clients/supabase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReadMessages } from "@/pages/dashboard/ReadMessages";
import {
  ArrowLeftFromLineIcon,
  ArrowRightFromLineIcon,
  HomeIcon,
} from "lucide-react";
import { formatDate } from "@/utils/datetime";
import AccountButton from "@/components/account/AccountButton";

function DashboardHome() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { chatId } = useParams();
  const navigate = useNavigate();

  // ** ReadChats **
  const [chats, setChats] = useState<any[]>([]);
  async function getChats() {
    try {
      const response = await supabase
        .from("chat")
        .select("id, created_at")
        .eq("user_id", session.user.id)
        .order("created_at", { ascending: false }); // Newest chats first

      const chatsResponse = response.data || [];
      return chatsResponse;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const [initialized, setInitialized] = useState<boolean>(false);
  async function initializeData() {
    const chatsResponse = await getChats();
    setChats(chatsResponse);

    // If the user has no chats, then redirect to the dashboard
    if (chatsResponse.length <= 0) {
      navigate("/dashboard");
    }
    setInitialized(true);
  }

  useEffect(() => {
    if (!initialized) {
      initializeData();
    }
  }, []);

  async function handleChatClick(id: string) {
    navigate(`/dashboard/chats/${id}`);
  }

  const [sidebarVisible, setSidebarVisible] = useState<any>(true);

  if (initialized) {
    return (
      <>
        <div
          className={`
        grid grid-cols-1 gap-1 transition-[grid-template-columns] lg:gap-2
        ${sidebarVisible ? "grid-cols-[14rem_1fr]" : "grid-cols-[4rem_1fr]"}
      `}
        >
          {sidebarVisible ? (
            <aside
              id="default-sidebar"
              className="bg-gray-200"
              aria-label="Sidebar"
            >
              <div className="flex h-screen flex-col justify-between border-e bg-neutral-100">
                <div className="px-2 py-4 overflow-y-auto overflow-x-hidden">
                  <div className="inline-flex pb-3 border-b border-gray-300">
                    <Link
                      className="mr-2 grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                      to="/"
                    >
                      <HomeIcon size={20} />
                    </Link>

                    <Link
                      className="grid h-10 w-24 place-content-center rounded-lg bg-gray-200 text-sm text-gray-900 hover:bg-gray-300"
                      to="/dashboard"
                    >
                      New Project
                    </Link>

                    <button
                      className="ml-2 grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                      onClick={() => setSidebarVisible(false)}
                    >
                      <ArrowLeftFromLineIcon size={20} />
                    </button>
                  </div>

                  <ul className="mt-6">
                    {chats &&
                      chats.length > 0 &&
                      chats.map((chat, index) => (
                        <button
                          key={index}
                          onClick={() => handleChatClick(chat.id)}
                          className={`font-sm px-2 py-2 rounded-lg text-gray-600
                        ${chatId === chat.id ? "bg-gray-200" : "hover:bg-gray-200"}
                      `}
                        >
                          {formatDate(chat.created_at)}
                        </button>
                      ))}
                  </ul>
                </div>

                <div className="sticky inset-x-0 bottom-0">
                  <AccountButton />
                </div>
              </div>
            </aside>
          ) : (
            <aside
              id="default-sidebar"
              className="bg-gray-100 flex h-screen w-16 flex-col justify-between border-e"
              aria-label="Sidebar"
            >
              <div className="pt-4 inline-flex h-16 w-16 items-center justify-center">
                <button
                  className="grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                  onClick={() => setSidebarVisible(true)}
                >
                  <ArrowRightFromLineIcon size={20} />
                </button>
              </div>
            </aside>
          )}

          <ReadMessages key={chatId} chats={chats} />
        </div>
      </>
    );
  }
}

export default DashboardHome;
