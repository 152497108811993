import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import posthog from "posthog-js";

type SelectFrameworkProps = {
  selectedFramework: string;
  setSelectedFramework: (value: string) => void;
};

function SelectFramework({
  selectedFramework,
  setSelectedFramework,
}: SelectFrameworkProps) {
  function handleSelect(value: string) {
    setSelectedFramework(value);
  }
  posthog.capture("framework_selected", { framework: selectedFramework });

  return (
    <>
      <small className="text-sm font-medium leading-none">
        Javascript Framework
      </small>
      <Select onValueChange={handleSelect}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="No Javascript" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectGroup>
            <SelectItem className="hover:bg-gray-100" value="no_javascript">
              No Javascript
            </SelectItem>
            <SelectItem className="hover:bg-gray-100" value="javascript">
              Javascript
            </SelectItem>
            <SelectItem className="hover:bg-gray-100" value="alpine">
              Alpine.js
            </SelectItem>
            <SelectItem className="hover:bg-gray-100" value="react">
              React
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
}

export { SelectFramework };
