import { Skeleton } from "@radix-ui/themes";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "@/contexts/SessionContext";
import axios from "axios";
import { EditMessage } from "@/pages/dashboard/EditMessage";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import Feedback from "@/components/editor/Feedback";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import {
  ChevronsLeftIcon,
  ChevronsRightIcon,
  CopyCheckIcon,
  CopyIcon,
  ExternalLinkIcon,
} from "lucide-react";
import { Toaster } from "@/components/ui/sonner";
import { usePostHog } from "posthog-js/react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const tailwindCDN = "https://cdn.tailwindcss.com";
const alpineCDN = "https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js";

type ReadMessagesProps = {
  chats: Array<any>;
};

function ReadMessages({ chats }: ReadMessagesProps) {
  const posthog = usePostHog();
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const navigate = useNavigate();

  const { chatId } = useParams();
  const chat = chats.find((chat) => chat.id === chatId);

  // ** ReadMessages **
  const [messages, setMessages] = useState<any[]>([]);

  async function getMessages(chatId: string) {
    if (!chatId) return;
    const getMessagesUrl = `${BACKEND_URL}/api/chats/${chatId}/messages`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.get(getMessagesUrl, { headers });
      const messagesResponse = response.data || [];

      if (messagesResponse.length === 0) {
        navigate("/dashboard/chats"); // Redirect if no messages found
      }
      return messagesResponse;
    } catch (error) {
      console.log(error);
      return []; // Return an empty array in case of an error
    }
  }

  // ** ReadMessage **
  const [messageIndex, setMessageIndex] = useState<number>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [files, setFiles] = useState<any>(null);

  async function initializeData(chatId: string) {
    if (chatId) {
      const messagesResponse = await getMessages(chatId);
      setMessages(messagesResponse);
      if (messagesResponse.length > 0) {
        const lastMessageIndex = messagesResponse.length - 1;
        setMessageIndex(lastMessageIndex);
        setFiles({
          "/index.html": {
            code: messagesResponse[lastMessageIndex].full_text,
          },
          "/index.js": {
            code: "",
          },
        });
      }
    }
    setInitialized(true);
  }

  // 2 -> 4 (2)
  useEffect(() => {
    if (!initialized) {
      initializeData(chat.id);
    }
  }, []);

  async function getSignedUrl(messageId: string, chatId: string) {
    const url = `${BACKEND_URL}/api/chats/${chatId}/messages/signedurl`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.post(url, { id: messageId }, { headers });
      const signedUrl = response.data;
      return signedUrl;
    } catch (error) {
      console.error(error);
    }
  }

  const [retrievedSignedURL, setRetrievedSignedURL] = useState(false);

  async function getSignedUrls() {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].screenshot) {
        continue; // Skip if screenshot is already present
      }
      const signedUrl = await getSignedUrl(messages[i].id, chat.id);
      const newArray = [...messages];
      newArray[i].screenshot = signedUrl;
      setMessages(newArray);
    }
    setRetrievedSignedURL(true);
  }

  // 4 -> 8 (always 4)
  useEffect(() => {
    if (initialized && messages.length > 0 && !retrievedSignedURL) {
      getSignedUrls();
    }
  }, [initialized]);

  const [slideoverVisible, setSlideoverVisible] = useState<boolean>(false);

  function handleMessageClick(id: string) {
    const index = messages.findIndex((message) => message.id === id);
    setMessageIndex(index);
    setFiles({
      "/index.html": {
        code: messages[index].full_text,
      },
      "/index.js": {
        code: "",
      },
    });
  }

  function handleRedo() {
    if (messageIndex > 0 && messages.length > 1) {
      setMessageIndex(messageIndex - 1);
    }
  }

  function handleUndo() {
    if (messageIndex < messages.length - 1) {
      setMessageIndex(messageIndex + 1);
    }
  }

  const [shareNotification, setShareNotification] = useState(false);

  function shareToClipboard() {
    const url = `${window.location.origin}/render/${chat.id}?version=${messages.length - messageIndex}`;
    navigator.clipboard.writeText(url);
    setShareNotification(true);

    const timer = setTimeout(() => {
      setShareNotification(false);
    }, 700);

    return () => clearTimeout(timer);
  }

  const [reloadedData, setReloadedData] = useState(false);

  async function reloadData() {
    const messagesResponse = await getMessages(chat.id);
    if (messagesResponse.length > 0) {
      setMessages(messagesResponse);
      setMessageIndex(messagesResponse.length - 1);
      getSignedUrls();
    } else {
      navigate("/dashboard/chats");
    }
    setReloadedData(true);
  }

  useEffect(() => {
    if (initialized && chat && !reloadedData) {
      reloadData();
    }
  }, [chatId, chats]);

  const [visualEditorEnabled, setVisualEditorEnabled] = useState(false);

  function toggleVisualEditor() {
    setVisualEditorEnabled(!visualEditorEnabled);
    posthog.capture("toggleVisualEditor", {
      message_id: messages[messageIndex].id,
      visualEditorEnabled: visualEditorEnabled,
    });
  }

  return (
    <>
      <main className="mr-2 h-32 rounded-lg">
        <div className="flex flex-wrap justify-center space-x-2 my-2 mr-2">
          <div className="rounded-lg">
            <span className="inline-flex items-center rounded-md bg-white px-2 py-1 text-sm text-gray-600 ring-1 ring-inset ring-neutral-300">
              <span className="text-neutral-700">Version: &nbsp;</span>
              {messages.length > 0 ? (
                <>
                  {" "}
                  {messages.length - messageIndex} of {messages.length}{" "}
                </>
              ) : (
                <> 0 of 0</>
              )}
              <br />
            </span>
          </div>

          <div className="grow-0 md:grow lg:grow"></div>

          <Feedback isVisible={true} message={messages[messageIndex]} />
        </div>

        <div className="rounded-lg h-fit">
          <div className="rounded-lg lg:col-span-2 mb-2">
            <div className="flex flex-wrap justify-center space-x-2">
              <div className="relative">
                {!visualEditorEnabled && (
                  <span className="text-xs rounded-t px-2 py-0.5 bg-cyan-300 absolute top-[-1.8em]">
                    New
                  </span>
                )}
                <Button
                  variant={"outline"}
                  className={`
                  ${!visualEditorEnabled && "w-[106px] ring ring-2 ring-offset-2 ring-cyan-300 "}
                `}
                  onClick={() => toggleVisualEditor()}
                >
                  {visualEditorEnabled ? "Code" : "Visual"}
                  &nbsp;Editor
                </Button>
              </div>

              <span id="product-tour-9">
                <Button
                  variant={"outline"}
                  onClick={() => setSlideoverVisible(true)}
                >
                  History
                </Button>
              </span>

              <Button
                variant={"outline"}
                onClick={handleUndo}
                disabled={messageIndex + 1 < messages.length ? false : true}
              >
                <ChevronsLeftIcon /> &nbsp; Previous
              </Button>

              <Button
                variant={"outline"}
                onClick={handleRedo}
                disabled={messageIndex > 0 ? false : true}
              >
                Next &nbsp;
                <ChevronsRightIcon />
              </Button>

              {messages[messageIndex] && messages[messageIndex].hash ? (
                <>
                  <Button
                    variant={"outline"}
                    onClick={() =>
                      window.open(
                        `/dashboard/chats/${chatId}/preview?version=${messageIndex + 1}`,
                        "_blank",
                      )
                    }
                  >
                    <ExternalLinkIcon /> &nbsp; Preview
                  </Button>

                  <Button
                    variant={"outline"}
                    onClick={shareToClipboard}
                    className="w-36"
                  >
                    {shareNotification ? (
                      <>
                        <CopyCheckIcon color="lightgreen" />
                        <span className="text-green-400">
                          &nbsp; Copied Link
                        </span>
                      </>
                    ) : (
                      <>
                        <CopyIcon /> &nbsp; Share Link
                      </>
                    )}
                  </Button>

                  <Button
                    variant={"outline"}
                    onClick={() =>
                      posthog.capture("Convert to CSS", {
                        message_id: messages[messageIndex].id,
                      })
                    }
                  >
                    Convert to CSS
                  </Button>
                </>
              ) : (
                <>
                  <Skeleton className="w-28 h-10 rounded-md bg-gray-100" />
                  <Skeleton className="w-28 h-10 rounded-md bg-gray-100" />
                  <Skeleton className="w-28 h-10 rounded-md bg-gray-100" />
                </>
              )}
            </div>
          </div>

          {initialized ? (
            <SandpackProvider
              {...(files && { files })}
              theme="auto"
              template="vanilla"
              options={{
                externalResources: [tailwindCDN, alpineCDN],
                autoReload: true,
                autorun: true,
                initMode: "lazy",
                bundlerTimeOut: 1_800_0000,
                visibleFiles: ["/index.html"],
                activeFile: "/index.html",
              }}
              autoSave={"true"}
            >
              <EditMessage
                messageIndex={messageIndex}
                setMessageIndex={setMessageIndex}
                messages={messages}
                setMessages={setMessages}
                visualEditorEnabled={visualEditorEnabled}
              />
            </SandpackProvider>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-0 lg:grid-cols-2 lg:gap-0 mt-2">
                <Skeleton className="bg-gray-200 rounded-l-xl w-[98%] lg:w-[98%] sm:w-[98%] mb-4 h-64 lg:h-[75vh]" />
                <Skeleton className="bg-gray-200 rounded-r-xl w-[98%] lg:w-[98%] sm:w-[98%] mb-4 h-64 lg:h-[75vh]" />
              </div>
              <Skeleton className="rounded-xl h-[50px] w-[800px] mx-auto bg-gray-100" />
            </>
          )}
        </div>

        <div className="relative z-50 w-auto h-auto">
          {slideoverVisible && (
            <div className="relative z-[99] fixed inset-0 overflow-auto font-mono">
              <div
                className="fixed inset-0 bg-black bg-opacity-10"
                onClick={() => setSlideoverVisible(false)}
              ></div>
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <div
                      className="w-screen max-w-md"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="flex flex-col h-full py-5 overflow-y-scroll bg-white border-l shadow-lg border-neutral-100/70">
                        <div className="px-4 sm:px-5">
                          <div className="flex items-start justify-between pb-1">
                            <h2 className="text-xl font-semibold leading-6 text-gray-900">
                              History
                            </h2>
                            <div className="flex items-center h-auto ml-3">
                              <button
                                onClick={() => setSlideoverVisible(false)}
                                className="absolute top-0 right-0 z-30 flex items-center justify-center px-3 py-2 mt-4 mr-5 space-x-1 text-xs font-medium uppercase border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-100 bg-white"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                                <span>Close</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        {messages &&
                          messages.length > 0 &&
                          slideoverVisible && (
                            <div className="relative flex-1 px-4 mt-5">
                              <div className="absolute inset-0 px-4 px-7">
                                <div className="relative h-full rounded-md">
                                  <div className="mt-8">
                                    <div className="flow-root">
                                      <ul role="list" className="-my-6 ">
                                        {messages.map((message, index) => (
                                          <div key={index} className="mb-4">
                                            <TooltipProvider
                                              delayDuration={400}
                                            >
                                              <Tooltip>
                                                <TooltipTrigger className="cursor-default">
                                                  <span
                                                    className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset
                                              ${index === messageIndex ? "bg-orange-50 ring-orange-400/50 ring-1 ring-inset" : "bg-gray-100 ring-gray-300"}
                                            `}
                                                  >
                                                    Version{" "}
                                                    {messages.length - index}.{" "}
                                                    {message.prompt.length > 30
                                                      ? `${message.prompt.slice(0, 30)}...`
                                                      : message.prompt}
                                                  </span>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-white">
                                                  <p>{message.prompt}</p>
                                                </TooltipContent>
                                              </Tooltip>
                                            </TooltipProvider>

                                            <li
                                              className={`my-1 flex border-2 rounded-lg 
                                        ${index === messageIndex ? "border-orange-200" : "hover:border-gray-400"}`}
                                            >
                                              <button
                                                className={`w-full flex justify-center items-center transition duration-150 ease-in-ease-out motion-safe text-neutral-700`}
                                                disabled={
                                                  index === messageIndex
                                                }
                                                onClick={() =>
                                                  handleMessageClick(message.id)
                                                }
                                              >
                                                {message.screenshot ? (
                                                  <img
                                                    className="object-cover object-center w-[388px] h-[216px] mx-auto rounded-lg"
                                                    src={message.screenshot}
                                                    alt="Preview Image"
                                                  />
                                                ) : (
                                                  <Skeleton className="object-cover object-center w-full h-[216px] mx-auto rounded-lg" />
                                                )}
                                              </button>
                                            </li>
                                          </div>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Toaster position="bottom-right" theme="light" richColors={true} />
      </main>
    </>
  );
}

export { ReadMessages };
