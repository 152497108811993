import {Gallery} from "@/components/ui/Gallery";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

type ShowcaseProps = {
  limit: number;
};

function Showcase({ limit }: ShowcaseProps) {
  const [messages, setMessages] = useState<Array<any>>([]);

  const readPublicScreenshots = useCallback(() => {
    const url = `${BACKEND_URL}/api/public/screenshots`;

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    readPublicScreenshots();
  }, []);

  return (
    <>
      <section className="px-2 mt-10 lg:mt-32 mb-5 lg:mb-16 bg-white md:px-0">
        <div className="container px-6 mx-auto">
          <h2 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
            Featured
          </h2>

          <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <Gallery messages={messages} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Showcase;
