import { useActiveCode } from "@codesandbox/sandpack-react";
import { useEffect, useState } from "react";

const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL;

function conditionallyAddHTMLTags(html: string) {
  const hasHtmlTag = html.match(/<\s*html[^>]*>/i);
  const hasHeadTag = html.match(/<\s*head(\s+[^>]*)?\s*>/i);
  const hasTailwindCdn = html.includes("cdn.tailwindcss.com");

  let hasAlpineCdn = false;

  if (html.includes("jsdelivr.net/npm/alpinejs")) {
    hasAlpineCdn = true;
  } else if (html.includes("unpkg.com/alpinejs")) {
    hasAlpineCdn = true;
  }

  let headHtml: string = "";

  if (!hasTailwindCdn) {
    headHtml = `<link href="https://cdn.tailwindcss.com" rel="stylesheet" id="external-css">`;
  }

  if (!hasAlpineCdn) {
    headHtml += `\n<script src="https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js" id="external-js"></script>`;
  }

  if (!hasHeadTag) {
    headHtml = `<head>\n${headHtml}\n</head>`;
  }

  if (!hasHtmlTag) {
    html = `<html lang="en">\n${html}\n</html>`;
  } else {
    html = html.replace(/<\s*html[^>]*>/i, `<html lang="en">\n${headHtml}`);
  }
}

const topHtml = `
<head id="topHtml">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <script src="https://cdn.tailwindcss.com" id="external-js"></script>
  <script src="https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js" id="external-js"></script>
</head>
`;

// This script is injected into the bottom of the iframe.
// It listens for clicks on the iframe and sends the HTML of the iframe and the clicked element to the parent window.
// It also toggles a makedraft-data-id to the clicked element and highlights it.
//
// We need to interact with a custom attribute we define as makedraft-data-id in the script. This avoids overwriting any preexisting ids on the element.
//
// You can also add additional metadata like tagName or id:
//    const iframeData = {
//      iframeHtml: document.body.outerHTML,
//      element: element.outerHTML,
//      elementTagName: element.tagName,
//      elementId: element.id,
//      elementDataId: element.getAttribute('makedraft-data-id'),
//    };
//
const bottomHtml = `
<script id="bottomHtml">
  function handleClick(event) {
    event.preventDefault();
    let currentElement = event.target;

    const activeElements = document.querySelectorAll('[makedraft-data-id]');
    const activeElementsArray = Array.from(activeElements);

    const isCurrentElementAlreadyActive = activeElementsArray.some(
      element => element === currentElement
    );

    if (activeElements.length > 0) {
      for (let i = 0; i < activeElements.length; i++) {
        const lastElement = activeElements[i];

        lastElement.classList.remove(
          'outline-dashed', 
          'outline-offset-2',
          'outline-cyan-400'
        );

        lastElement.removeAttribute('makedraft-data-id');
      };
    };

    if (isCurrentElementAlreadyActive) {
      window.parent.postMessage({ message: null }, '*');
      return;
    };
    
    let iframeData = {
      iframeHtml: document.body.outerHTML,
      element: currentElement.outerHTML,
      elementTagName: currentElement.tagName,
      elementId: currentElement.id,
      elementDataId: currentElement.getAttribute('makedraft-data-id'),
    };

    currentElement.setAttribute('makedraft-data-id', '');
    currentElement.classList.add('outline-dashed');
    currentElement.classList.add('outline-offset-2');
    currentElement.classList.add('outline-cyan-400');

    window.parent.postMessage({ message: iframeData }, '*');
  };

  document.body.addEventListener('click', handleClick);
</script>`;

const oldBottomHtml = `
<script id="bottomHtml">
  let lastElementDataId = '';

  function handleClick(event) {
    event.preventDefault();
    const element = event.target;
    
    const iframeData = {
      iframeHtml: document.body.outerHTML,
      element: element.outerHTML,
      elementTagName: element.tagName,
      elementId: element.id,
      elementDataId: element.getAttribute('makedraft-data-id'),
    };

    if (lastElementDataId) {
      const lastElements = document.querySelectorAll(
        '[makedraft-data-id="' + lastElementDataId + '"]'
      );

      if (lastElements.length > 0) {
        for (let i = 0; i < lastElements.length; i++) {
          const lastElement = lastElements[i];

          lastElement.classList.remove(
            'outline-dashed', 
            'outline-offset-2',
            'outline-cyan-400'
          );

          if (lastElement === element) {
            continue;
          };
        };
      };

      // If the CURRENT element is the same as the one that was last clicked on, de-highlight it and exit the function.
      // Current element is the one that was just clicked on.
      // Current element has no data-id but is about to get a data-id.
      if (element.getAttribute('makedraft-data-id') === lastElementDataId) {
        element.removeAttribute('makedraft-data-id');
        window.parent.postMessage({ message: null }, '*');
        return;
      };
    };

    lastElementDataId = crypto.randomUUID();
    
    element.setAttribute('makedraft-data-id', lastElementDataId);
    element.classList.toggle('outline-dashed');
    element.classList.toggle('outline-offset-2');
    element.classList.toggle('outline-cyan-400');

    window.parent.postMessage({ message: iframeData }, '*');
  };

  document.body.addEventListener('click', handleClick);
</script>
</html>`;

// Unused. But this adds a data-id to all elements in the HTML string.
function addDataIdToHtmlElements(html: string): string {
  // Step 1: Parse the HTML string into a DOM
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Step 2: Iterate over all elements and add `data-id` attributes
  const elements = doc.body.querySelectorAll("*"); // Selects all elements within the body
  let n = 0;
  elements.forEach((element) => {
    element.setAttribute(`data-id`, `${n++}`); // Step 3
  });

  // Step 4: Serialize the modified DOM back into a string
  // OuterHTML of 'html' gives the entire document's HTML including <html>, <head>, and <body> tags.
  // If only body's inner HTML is needed, use doc.body.innerHTML
  return doc.documentElement.outerHTML;
}

type IframePreviewProps = {
  setSelectedText: (text: string) => void;
  setSelectedRange: (range: any) => void;
  setIframeCode: (code: string) => void;
};

function IframePreview({
  setSelectedText,
  setSelectedRange,
  setIframeCode,
}: IframePreviewProps) {
  const { code } = useActiveCode();

  function resetProps() {
    setSelectedRange(null);
    setSelectedText("");
  }

  // This hook listens for messages from the iframe.
  // The messages include the HTML of the iframe and the clicked element.
  // We use this to determine the position of the clicked element.
  // This lets us send the data to the backend in the /revise endpoint.
  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      // Check the origin of the message
      if (event.origin !== FRONTEND_URL) {
        return;
      }

      if (!event || !event.data) {
        return;
      }

      // Listen for iframe sending postMessage that's equal to null to reset the props
      // This is NOT intended to handle undefined. Null has a purpose here.
      if (event.data.message === null) {
        resetProps();
        return;
      }

      const iframeData = event.data.message;

      if (!iframeData) {
        return;
      }

      // const { iframeHtml, element, elementTagName, elementId } = iframeData;
      const { iframeHtml, element, elementTagName, elementId } = iframeData;

      const domIframe = new DOMParser().parseFromString(iframeHtml, "text/html")
        .children[0];

      // Remove the script tag
      domIframe.querySelector("#bottomHtml")?.remove();
      domIframe.querySelector("#topHtml")?.remove();

      // Extract the elements in the body
      const htmlString = domIframe.innerHTML;

      const startPosition = htmlString.indexOf(element);

      // Calculate start line and column
      const beforeElementHtml = htmlString.substring(0, startPosition);

      // +1 for 1-based indexing
      const startLineNumber = (beforeElementHtml.match(/\n/g) || []).length + 1;

      const lastNewLineIndex = beforeElementHtml.lastIndexOf("\n");

      // Columns start after the last newline
      const startColumn = startPosition - lastNewLineIndex;

      // Calculate end line and column
      const endPosition = startPosition + element.length;

      const afterElementHtml = htmlString.substring(0, endPosition);

      // +1 for 1-based indexing
      const endLineNumber = (afterElementHtml.match(/\n/g) || []).length + 1;

      // Column of the end position
      const endColumn = endPosition - afterElementHtml.lastIndexOf("\n");

      const position = {
        startLineNumber,
        startColumn,
        endLineNumber,
        endColumn,
      };

      setSelectedText(element);
      setSelectedRange(position);
      setIframeCode(htmlString);
    }

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup on component unmount
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <>
      <div
        className="pointer-events-auto w-[100%] h-[100%] border border-1 border-gray-900 rounded"
        role="dialog"
        aria-modal="true"
        style={{ zIndex: "99999" }}
      >
        <iframe
          id="iframe_id"
          srcDoc={topHtml + code + bottomHtml}
          referrerPolicy={"no-referrer"}
          className="select-none overflow-hidden border-none bg-white transition-opacity [content-visibility:auto] w-[100%] rounded"
          style={{ height: "75vh" }}
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    </>
  );
}

export { IframePreview };
