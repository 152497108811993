import { useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "@/clients/supabase";
import { useLocation, useNavigate } from "react-router-dom";

const frontendUrl = import.meta.env.VITE_FRONTEND_URL;

function Signup() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const navigate = useNavigate();
  const [
    redirectAfterEmailConfirmationUrl,
    setRedirectAfterEmailConfirmationUrl,
  ] = useState<string>(frontendUrl + "/dashboard");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prompt = queryParams.get("prompt");

  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });

    if (prompt) {
      setRedirectAfterEmailConfirmationUrl(
        frontendUrl + "/dashboard?prompt=" + prompt,
      );
    }

    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    if (!session) {
      return (
        <>
          <div className="flex fixed inset-0 z-[99] w-screen h-screen bg-white">
            <div className="relative top-0 bottom-0 right-0 flex-shrink-0 hidden w-1/3 overflow-hidden bg-cover lg:block">
              <a
                href="#_"
                className="absolute bottom-0 left-0 z-30 inline-flex items-end mb-4 ml-3 font-sans text-2xl font-extrabold text-left text-white no-underline bg-transparent cursor-pointer group focus:no-underline"
              >
                <span className="flex">Makedraft</span>
              </a>
              <div className="absolute inset-0 z-20 w-full h-full opacity-70 bg-gradient-to-t from-black"></div>
              <img
                src="https://images.unsplash.com/photo-1655998233171-ee5b130acba5?q=80&w=1899&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="z-10 object-cover w-full h-full"
              />
            </div>
            <div className="relative flex flex-wrap items-center w-full h-full px-8">
              <div className="relative w-full max-w-sm mx-auto lg:mb-0">
                <div className="relative">
                  <h1 className="text-2xl mb-6 text-center">Sign Up</h1>

                  <Auth
                    supabaseClient={supabase}
                    appearance={{
                      theme: ThemeSupa,
                      variables: {
                        default: {
                          colors: {
                            brand: "#f26969",
                            brandAccent: "#ef4444",
                          },
                        },
                      },
                    }}
                    providers={["github"]}
                    redirectTo={redirectAfterEmailConfirmationUrl}
                    view="sign_up"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      navigate("/dashboard");
    }
  }
}

export default Signup;
