import { useEffect, useState } from "react";
import { getCodeFromHash } from "@/utils/urlHash";
import { demoHtml } from "@/demoData";
import { Crisp } from "crisp-sdk-web";
import Video from "@/components/ui/Video";
import { Link } from "react-router-dom";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import { MockEditMessage } from "@/pages/MockEditMessage";
import Showcase from "@/pages/Showcase";

const crispWebsiteID = import.meta.env.VITE_CRISP_WEBSITE_ID;
const tailwindCDN = "https://cdn.tailwindcss.com";
const alpineCDN = "https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js";

function Home() {
  const [htmlCode, setHtmlCode] = useState<any>(demoHtml);
  const hashCode = getCodeFromHash();
  const [inited, setInited] = useState<any>(false);

  const files = {
    "/index.html": {
      code: htmlCode,
    },
    "/index.js": {
      code: "",
    },
  };

  useEffect(() => {
    Crisp.configure(crispWebsiteID);

    // To ensure this code block only runs once
    if (!inited) {
      // If hash is not empty, set htmlCode to the hash
      if (hashCode !== undefined) {
        setHtmlCode(getCodeFromHash());
      }
      setInited(true);
    }
  }, []);

  if (!inited) {
    return null;
  }

  return (
    <>
      <div className="mx-8 flex-wrap justify-center">
        <SandpackProvider
          theme="auto"
          template="vanilla"
          files={files}
          options={{
            externalResources: [tailwindCDN, alpineCDN],
            autoReload: true,
            autorun: true,
            initMode: "user-visible",
            visibleFiles: ["/index.html"],
            activeFile: "/index.html",
          }}
          autoSave={"true"}
        >
          <MockEditMessage />
        </SandpackProvider>
      </div>

      <Showcase limit={8} />

      <Video />

      <div className="bg-white p-8">
        <div className="max-w-4xl mx-auto">
          <div className="space-y-4">
            <h2 className="text-2xl font-semibold mb-6 text-center">FAQ</h2>
            <details
              className="group [&_summary::-webkit-details-marker]:hidden"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">What is Makedraft?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                Makedraft is an AI that generates frontend code based on your
                instructions. You can then copy the HTML to any project.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Does it only generate HTML?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                It also generates Javascript, as well as Alpine.js. Vue.js is
                coming soon.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">
                  Can I use the code for commercial purposes?
                </h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                You can use the generated code for commercial purposes.
                It&apos;s your responsibility to ensure that your use of
                Makedraft complies with all relevant laws and regulations.
                Please note that Makedraft reserves the right to use the code
                for any purpose. Additionally, you are not permitted to compile
                Makedraft generated code to replicate a similar or competing
                service.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Is what I generate private?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                Makedraft will be introducing a Showcase for people to see
                Showcase projects. Makedraft is currently in open beta and is
                free to use. A Pro plan will be available soon. Users who
                subscribe to the Pro plan will have have their generated
                projects set to private by default.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">
                  What do I need to use the generated code?
                </h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                The generated HTML are styled with{" "}
                <Link className="text-orange-500" to="https://tailwindcss.com">
                  Tailwind CSS
                </Link>
                . Just remember to add
                <code className="break-words bg-orange-100/75 rounded-lg px-1 py-1 font-semibold">
                  {"<script src='https://cdn.tailwindcss.com'></script>"}
                </code>{" "}
                to the
                <code className="font-semibold">{"<head>"}</code>
                element in your code. If you&apos;re generating Alpine.js code,
                make sure to also add
                <code className="break-words bg-orange-100/75 rounded-lg px-1 py-1 font-semibold">
                  {
                    "<script defer src='https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js'></script>"
                  }
                </code>
                .
              </p>
            </details>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
