import { Link } from "react-router-dom";

function Video() {
  return (
    <div>
      <section className="px-2 mt-10 lg:mt-32 mb-5 lg:mb-16 bg-white md:px-0">
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="flex flex-wrap items-center sm:-mx-3">
            <div className="w-full md:w-1/2 md:px-3">
              <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                  <span className="block xl:inline">
                    Edit Specific Sections{" "}
                  </span>
                </h1>
                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                  Highlight the code you want changed and instruct the AI on
                  what to change.
                </p>
                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                  <Link
                    to="/signup"
                    className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-red-400 rounded-2xl sm:mb-0 hover:bg-red-500 sm:w-auto"
                    data-primary="indigo-600"
                    data-rounded="rounded-md"
                  >
                    Get Started Free
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-1 feather feather-arrow-right"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div
                className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl"
                data-rounded="rounded-xl"
                data-rounded-max="rounded-full"
              >
                <iframe
                  className="aspect-video w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl"
                  src="https://www.youtube.com/embed/R_3OPD9CDdk?si=6VeJuHmQsE-iUGgR"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  title="Makedraft - Edit HTML with AI"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="px-2 py-32 bg-white md:px-0">
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="flex flex-wrap items-center sm:-mx-3">
            <div className="w-full md:w-1/2 md:px-3">
              <div
                className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl"
                data-rounded="rounded-xl"
                data-rounded-max="rounded-full"
              >
                <iframe
                  className="aspect-video w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl"
                  src="https://www.youtube.com/embed/NNBfvS1CxUk?si=maSdPTaoHaycpAPa"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  title="Makedraft - Generate HTML with AI"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="w-full md:w-1/2 md:px-3 mt-5 lg:mt-0">
              <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                  <span className="block xl:inline">
                    Generate HTML Components
                  </span>
                </h1>
                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                  Prompt the AI without highlighting any text to generate
                  entirely new HTML components.
                </p>
                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                  <Link
                    to="https://docs.makedraft.com"
                    className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-orange-400 rounded-2xl sm:mb-0 hover:bg-orange-500 sm:w-auto"
                    data-primary="indigo-600"
                    data-rounded="rounded-md"
                  >
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-1 feather feather-arrow-right"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Video;
