import { useEffect, useState, useContext } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  SandpackProvider,
  SandpackLayout,
  SandpackPreview,
} from "@codesandbox/sandpack-react";
import axios from "axios";
import {
  CircleDashedIcon,
  CopyCheckIcon,
  CopyIcon,
  GitForkIcon,
} from "lucide-react";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import { LoginModal } from "@/pages/auth/LoginModal";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const tailwindCDN = "https://cdn.tailwindcss.com";
const alpineCDN = "https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js";

function Render() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { messageId } = useParams();
  const { chatId } = useParams();
  const navigate = useNavigate();

  // ** ReadMessage **
  // Props
  const [message, setMessage] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  // Get message
  const getMessage = (messageId: string) => {
    const url = `${BACKEND_URL}/api/public/chats/messages/${messageId}`;
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        setMessage(response.data);

        // Set files
        const html = message.full_text;

        setFiles({
          "/index.html": {
            code: html,
          },
          "/index.js": {
            code: "",
          },
        });

        setInitialized(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (message && message?.full_text) {
      const html = message.full_text;
      console.log(html);
      setFiles({
        "/index.html": {
          code: html,
        },
        "/index.js": {
          code: "",
        },
      });
      setInitialized(true);
    }
  }, [message]);

  // Fork
  const [isForking, setIsForking] = useState(false);
  const forkChat = () => {
    setIsForking(true);

    // If not logged in, send a toast notification and exit the function
    if (!session || !session.access_token) {
      setIsForking(false);

      toast.warning(
        <>
          <div data-content="">
            <div data-title="" className="">
              You must be logged in to fork a project.
            </div>
          </div>
          <LoginModal>
            <Button
              variant="outline"
              className="px-6 py-1 text-xs border-0 ml-auto h-6 w-7 bg-white text-black font-normal"
              style={{ position: "relative" }}
            >
              Login
            </Button>
          </LoginModal>
        </>,
      );
      return;
    }

    // If user is logged in, then fork the message
    const forkChatUrl = `${BACKEND_URL}/api/chats/${chatId}/fork`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    axios
      .post(forkChatUrl, {}, { headers })
      .then((response) => {
        setIsForking(false);
        const forkedChatId = response.data;

        toast.success("Successfully forked", {
          action: {
            label: "View Fork",
            onClick: () => {
              navigate(`/dashboard/chats/${forkedChatId}`);
            },
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsForking(false);
        toast.error("Error while forking");
      });
  };

  // Share - Copy to clipboard
  const [shareNotification, setShareNotification] = useState(false);
  const shareToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setShareNotification(true);

    const timer = setTimeout(() => {
      setShareNotification(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  if (!initialized) {
    getMessage(messageId);
  }

  return (
    <>
      {initialized ? (
        <SandpackProvider
          {...(files && { files })}
          theme="auto"
          template="vanilla"
          options={{
            externalResources: [tailwindCDN, alpineCDN],
            autoReload: true,
            autorun: true,
            initMode: "lazy",
            bundlerTimeOut: 1_800_0000,
            visibleFiles: ["/index.html"],
            activeFile: "/index.html",
          }}
        >
          <SandpackLayout>
            <SandpackPreview
              showOpenInCodeSandbox={false}
              showRefreshButton={false}
              style={{
                height: "99.83vh",
              }}
              actionsChildren={
                <>
                  {/* Do not flip this conditional around. This way of doing things prevents it from throwing an error if not logged in. */}
                  {!session || !session.access_token ? (
                    <LoginModal>
                      <button
                        type="button"
                        className="z-30 flex items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                        style={{
                          fontSize: "0.875rem",
                        }}
                      >
                        <span>Login</span>
                      </button>
                    </LoginModal>
                  ) : (
                    <button
                      type="button"
                      className="z-30 flex items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                      style={{
                        fontSize: "0.875rem",
                      }}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      <span>Dashboard</span>
                    </button>
                  )}

                  <button
                    type="button"
                    className="z-30 flex w-[7.5rem] px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                    style={{
                      fontSize: "0.875rem",
                    }}
                    onClick={shareToClipboard}
                  >
                    {shareNotification ? (
                      <>
                        <CopyCheckIcon height={16} />
                        <span>Copied!</span>
                      </>
                    ) : (
                      <>
                        <CopyIcon height={16} />
                        <span>Share Link</span>
                      </>
                    )}
                  </button>

                  <button
                    type="button"
                    className="z-30 flex w-20 items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white mr-3"
                    style={{
                      fontSize: "0.875rem",
                    }}
                    onClick={forkChat}
                  >
                    {isForking ? (
                      <>
                        <CircleDashedIcon
                          className="animate-spin"
                          height={16}
                        />
                      </>
                    ) : (
                      <>
                        <GitForkIcon height={16} />
                        <span>Fork</span>
                      </>
                    )}
                  </button>
                </>
              }
            />
          </SandpackLayout>
        </SandpackProvider>
      ) : (
        <div className="flex h-screen items-center">
          <div className="mx-auto flex flex-col space-y-3">
            <Skeleton className="h-[125px] w-[250px] bg-gray-100 rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="rounded-md h-4 w-[250px] bg-gray-100" />
              <Skeleton className="rounded-md h-4 w-[200px] bg-gray-100" />
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="top-right"
        theme="light"
        expand={true}
        closeButton={true}
      />
    </>
  );
}

export default Render;
