import { Link, useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftFromLine, ArrowRightFromLine, HomeIcon } from "lucide-react";
import AccountButton from "@/components/account/AccountButton";
import { useFlows } from "@frigade/react";
import { formatDate } from "@/utils/datetime";

const FRIGADE_FLOW_ID = import.meta.env.VITE_FRIGADE_FLOW_ID;

type ReadChatsProps = {
  chats: any[];
  chatId?: string;
  sidebarVisible: boolean;
  setSidebarVisible: any;
};

function ReadChats({
  chats,
  chatId,
  sidebarVisible,
  setSidebarVisible,
}: ReadChatsProps) {
  const navigate = useNavigate();

  function handleChatClick(id: string) {
    navigate(`/dashboard/chats/${id}`);
    window.location.reload();
  }

  function toggleSidebar() {
    markStepCompleted(FRIGADE_FLOW_ID, "welcome-four");
    setSidebarVisible(!sidebarVisible);
  }

  // FRIGADE: Product Tour
  const { markStepCompleted } = useFlows();
  const location = useLocation();
  const pathname = location.pathname;
  const isDashboardChatsPath = pathname.startsWith("/dashboard/chats/");

  return (
    <>
      {sidebarVisible ? (
        <>
          <aside
            id="default-sidebar"
            className="bg-gray-200"
            aria-label="Sidebar"
          >
            <div className="flex h-screen flex-col justify-between border-e bg-neutral-100">
              <div className="px-2 py-4 overflow-y-auto overflow-x-hidden">
                <div className="inline-flex pb-3 border-b border-gray-300">
                  <Link
                    className="mr-2 grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                    to="/"
                  >
                    <HomeIcon size={20} />
                  </Link>

                  <Link
                    className="grid h-10 w-24 place-content-center rounded-lg bg-gray-200 text-sm text-gray-900 hover:bg-gray-300"
                    to="/dashboard"
                  >
                    New Project
                  </Link>

                  {/* This ternary operator is only here for the product-tour */}
                  {isDashboardChatsPath ? (
                    <span
                      onClick={() => {
                        markStepCompleted(FRIGADE_FLOW_ID, "welcome-four");
                      }}
                      id={chats && chats.length > 0 ? "product-tour-4" : ""}
                    >
                      <button
                        className="ml-2 grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                        onClick={toggleSidebar}
                      >
                        <ArrowLeftFromLine size={20} />
                      </button>
                    </span>
                  ) : (
                    <button
                      className="ml-2 grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
                      onClick={toggleSidebar}
                    >
                      <ArrowLeftFromLine size={20} />
                    </button>
                  )}
                </div>

                <ul className="mt-6">
                  {chats && chats.length > 0 ? (
                    chats.map((chat, index) => (
                      <li key={index} className="">
                        <button
                          key={index}
                          onClick={() => {
                            handleChatClick(chat.id);
                          }}
                          className={`font-sm px-2 py-2 rounded-lg text-gray-600
                          ${chatId && chatId === chat.id ? `bg-gray-200 ` : `hover:bg-gray-200`}
                        `}
                        >
                          {formatDate(chat.created_at)}
                        </button>
                      </li>
                    ))
                  ) : (
                    <span></span>
                  )}
                </ul>
              </div>

              <div className="sticky inset-x-0 bottom-0">
                <AccountButton />
              </div>
            </div>
          </aside>
        </>
      ) : (
        <aside
          id="default-sidebar"
          className="bg-gray-100 flex h-screen w-16 flex-col justify-between border-e"
          aria-label="Sidebar"
        >
          <div className="pt-4 inline-flex h-16 w-16 items-center justify-center">
            <button
              className="grid h-10 w-10 place-content-center rounded-lg bg-gray-200 text-xs text-gray-600 hover:bg-gray-300"
              onClick={toggleSidebar}
            >
              <ArrowRightFromLine size={20} />
            </button>
          </div>
        </aside>
      )}
    </>
  );
}

export default ReadChats;
