import { useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "@/clients/supabase";

const frontendUrl = import.meta.env.VITE_FRONTEND_URL;
const redirectAfterEmailConfirmationUrl = frontendUrl + "/dashboard";
const stripePaymentLinkId = import.meta.env.VITE_STRIPE_PAYMENT_LINK_ID;
const paymentLink = `https://buy.stripe.com/${stripePaymentLinkId}`;

interface PaymentModalProps {
  className?: string;
  link?: string;
}

const PaymentModal = ({ className, link }: PaymentModalProps) => {
  // Auth
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);

  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  // Modal
  const [fullscreenModal, setFullscreenModal] = useState(false);

  useEffect(() => {
    if (fullscreenModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Function to handle escape key press
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setFullscreenModal(false);
      }
    };

    // Add event listener for escape key press
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [fullscreenModal]);

  if (isLoading) {
    return <div></div>;
  } else {
    if (!session) {
      return (
        <>
          <a
            onClick={() => setFullscreenModal(true)}
            href={link}
            className={className}
          >
            Get Started
          </a>

          {fullscreenModal && (
            <div className="flex fixed inset-0 z-[99] w-screen h-screen bg-white">
              <button
                onClick={() => setFullscreenModal(false)}
                className="absolute top-0 right-0 z-30 flex items-center justify-center px-3 py-2 mt-3 mr-3 space-x-1 text-xs font-medium uppercase border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span>Close</span>
              </button>

              {/* Rest of the modal content */}
              <div className="relative top-0 bottom-0 right-0 flex-shrink-0 hidden w-1/3 overflow-hidden bg-cover lg:block">
                <a
                  href="#_"
                  className="absolute bottom-0 left-0 z-30 inline-flex items-end mb-4 ml-3 font-sans text-2xl font-extrabold text-left text-white no-underline bg-transparent cursor-pointer group focus:no-underline"
                >
                  <span className="flex">Makedraft</span>
                </a>
                <div className="absolute inset-0 z-20 w-full h-full opacity-70 bg-gradient-to-t from-black"></div>
                <img
                  src="https://images.unsplash.com/photo-1655998233171-ee5b130acba5?q=80&w=1899&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className="z-10 object-cover w-full h-full"
                />
              </div>
              <div className="relative flex flex-wrap items-center w-full h-full px-8">
                <div className="relative w-full max-w-sm mx-auto lg:mb-0">
                  <div className="relative">
                    <h1 className="text-2xl mb-6 text-center">Sign Up</h1>

                    <Auth
                      supabaseClient={supabase}
                      appearance={{
                        theme: ThemeSupa,
                        variables: {
                          default: {
                            colors: {
                              brand: "#4f46e5",
                              brandAccent: "#4338ca",
                            },
                          },
                        },
                      }}
                      providers={["github"]}
                      redirectTo={redirectAfterEmailConfirmationUrl}
                      view="sign_up"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else {
      if (link === "#free") {
        return (
          <a href={`/dashboard${link}`} className={className}>
            Get Started
          </a>
        );
      } else {
        return (
          <a
            href={`${paymentLink}?client_reference_id=${session.user.id}&prefilled_email=${session.user.email}`}
            className={className}
          >
            Get Started
          </a>
        );
      }
    }
  }
};

export default PaymentModal;
