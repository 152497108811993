import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Skeleton } from "@/components/ui/skeleton";

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;


type GalleryItemMessage = {
  id: string;
  created_at: string;
  prompt: string;
  chat_id: string;
  screenshot?: string; // presigned URL
  // username: string; // not used as it needs a profile table in Supabase
};

type GalleryProps = {
  messages: Array<GalleryItemMessage>;
};

function Gallery({ messages }: GalleryProps) {
  return (
    <>
      {messages.map((message, index) => (
        <span key={index}>
          { message.screenshot ? (
            <GalleryItem message={message} />
          ) : (
            <GallerySkeletonItem />
          )}
        </span>
      ))}
    </>
  );
}

function GallerySkeletonItem() {
  return (
    <div className="mx-auto flex flex-col space-y-3" >
      <Skeleton className="h-[190px] w-[318px] bg-gray-100 rounded-xl" />
      <div className="space-y-2">
        <Skeleton className="h-[32px] w-[320px] bg-gray-100 rounded-md" />
      </div>
    </div>
  );
}

type GalleryItemProps = {
  message: GalleryItemMessage;
};

function GalleryItem({ message }: GalleryItemProps) {
  return (
    <div className="w-full max-w-xs text-center">
      <a
        href={`/render/${message.chat_id}/${message.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          className="border border-1 hover:shadow-lg transition-all object-cover object-center w-full h-48 mx-auto rounded-lg"
          alt="preview"
          src={`${SUPABASE_URL}/storage/v1/object/public/public-screenshot/${message.id}.jpeg`}
        />
      </a>

      <TooltipProvider delayDuration={400}>
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            <p
              className="mt-2 rounded-lg px-4 py-2 bg-gray-100 text-left"
              style={{
                width: "20rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <span className="text-gray-700 truncate truncate-ellipsis">
                {message.prompt}
              </span>
            </p>
          </TooltipTrigger>
          <TooltipContent className="bg-white">
            <p>{message.prompt}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export { Gallery };
