import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import supabase from "@/clients/supabase";
import { useLocation, useNavigate } from "react-router-dom";
import ReadChats from "@/pages/dashboard/ReadChats";
import axios from "axios";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import { SelectFramework } from "@/components/editor/SelectFramework";
import { useFlows } from "@frigade/react";
import { PasteCodeModal } from "@/components/dashboard/PasteCodeModal";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const FRIGADE_FLOW_ID = import.meta.env.VITE_FRIGADE_FLOW_ID;
const stripePaymentLinkId = import.meta.env.VITE_STRIPE_PAYMENT_LINK_ID;
const paymentLink = `https://buy.stripe.com/${stripePaymentLinkId}`;

function NewChat() {
  // ** NewChat **
  // Logic related definitions
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const navigate = useNavigate();

  // ** ReadChats **
  // Props
  const [chats, setChats] = useState<any[]>([]);
  const [sidebarVisible, setSidebarVisible] = useState<any>(true);

  // Supabase Functions
  async function getChats() {
    try {
      const response = await supabase
        .from("chat")
        .select("id, created_at")
        .eq("user_id", session.user.id)
        .order("created_at", { ascending: false });

      setChats(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Hook Functions
  // On mount, getChats
  useEffect(() => {
    getChats();

    if (queryParams) {
      const urlSafePrompt = queryParams.get("prompt");
      if (urlSafePrompt) {
        const prompt = decodeURIComponent(urlSafePrompt);
        addExampleInput(prompt);
      }
    }
  }, []);

  // ** PasteCodeModal **
  const [pasteCodePrompt, setPasteCodePrompt] = useState<string>("");

  async function handleGenerate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevent the default form submission behavior
    setIsSubmitting(true); // Disable AI button when the POST request starts

    // FRIGADE: Complete a Step
    markStepCompleted(FRIGADE_FLOW_ID, "welcome-three");

    toast.info(
      "Generating HTML: This may take a while. If there's an error, you'll be notified.",
    );

    let prompt = event.target["prompt"].value;

    if (pasteCodePrompt) {
      prompt += `\nCode provided by the user:\n\`\`\`\n${pasteCodePrompt}\n\`\`\``;
    }

    const generateUrl = `${BACKEND_URL}/api/chats/messages/generate`; //v3

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.post(
        generateUrl,
        {
          prompt: prompt,
        },
        { headers: headers },
      );

      if (response.data) {
        navigate(`/dashboard/chats/${response.data.chat_id}`);
      }
    } catch (error) {
      // If status code is 402
      if (error.response.status === 402) {
        toast("Exceeded usage limit. Subscribe to remove limit.", {
          action: {
            label: "Subscribe",
            onClick: () => {
              window.open(
                `${paymentLink}?client_reference_id=${session.user.id}&prefilled_email=${session.user.email}`,
              );
            },
          },
        });
      } else {
        console.log(error);
        toast.error("Error: " + error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  // UI: Generate Button
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Select framework component definitions
  const [selectedFramework, setSelectedFramework] = useState("no_javascript");

  // FRIGADE: Product Tour
  const { markStepCompleted } = useFlows();

  function addExampleInput(prompt: string) {
    const exampleInput = document.getElementById("prompt");

    // @ts-expect-error: Property 'value' exists for input element
    exampleInput.value = prompt;
  }

  return (
    <>
      <div
        className={`
        grid grid-cols-1 transition-[grid-template-columns] 
        ${sidebarVisible ? "grid-cols-[14rem_1fr]" : "grid-cols-[4rem_1fr]"}
      `}
      >
        {chats ? (
          <ReadChats
            chats={chats}
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
          />
        ) : (
          <span></span>
        )}

        <main className="mr-2 rounded-lg  h-full">
          <div className="rounded-lg "></div>

          <div className="rounded-lg ">
            <div className="flex flex-wrap justify-center">
              <div className="mx-1 py-10">
                <span id="product-tour-1">
                  <SelectFramework
                    selectedFramework={selectedFramework}
                    setSelectedFramework={setSelectedFramework}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="h-2/5 xl:h-3/5 container mx-auto">
            <div className="mb-2 grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2">
              <div className="flex flex-col gap-2 justify-center mx-auto">
                <h3 className="text-2xl font-semibold text-center"></h3>
                <div className="mx-8 flex-wrap justify-center">
                  <PasteCodeModal
                    pasteCodePrompt={pasteCodePrompt}
                    setPasteCodePrompt={setPasteCodePrompt}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/2 container mx-auto">
            <div className="mb-2 grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2">
              <div className="flex flex-col gap-2">
                <button
                  onClick={() =>
                    addExampleInput("A dashboard with a sidebar and navbar")
                  }
                  className="hover:bg-neutral-100 border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-gray-700 dark:text-gray-300 md:whitespace-normal"
                >
                  <div className="flex w-full gap-2 items-center justify-center">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col overflow-hidden">
                        <div className="truncate text-neutral-700">
                          A dashboard with a sidebar and navbar
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() =>
                    addExampleInput(
                      "A table of financial invoices with modern UI",
                    )
                  }
                  className="hover:bg-neutral-100 border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-gray-700 dark:text-gray-300 md:whitespace-normal"
                >
                  <div className="flex w-full gap-2 items-center justify-center">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col overflow-hidden">
                        <div className="truncate text-neutral-700">
                          A table of financial invoices with modern UI
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => addExampleInput("A sign up form")}
                  className="hover:bg-neutral-100 border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-gray-700 dark:text-gray-300 md:whitespace-normal"
                >
                  <div className="flex w-full gap-2 items-center justify-center">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col overflow-hidden">
                        <div className="truncate text-neutral-700">
                          A sign up form
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  onClick={() =>
                    addExampleInput("A landing page for a sales CRM")
                  }
                  className="hover:bg-neutral-100 border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-gray-700 dark:text-gray-300 md:whitespace-normal"
                >
                  <div className="flex w-full gap-2 items-center justify-center">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col overflow-hidden">
                        <div className="truncate text-neutral-700">
                          A landing page for a sales CRM
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="">
            <div className="max-w-lg md:max-w-xl lg:max-w-4xl 2xl:max-w-6xl mx-auto">
              <form onSubmit={handleGenerate}>
                <div className="flex flex-wrap">
                  <span
                    id="product-tour-2"
                    className="w-full lg:w-2/3 ml-auto mr-2"
                  >
                    <input
                      id="prompt"
                      name="prompt"
                      type="text"
                      required
                      className=" mb-2 lg:mb-0 py-3.5 px-3.5 w-full rounded-xl border border-neutral-300 bg-white/5 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-inset focus:ring-gray-200 text-sm md:text-base lg:text-base disabled:bg-gray-100"
                      placeholder="Generate HTML with AI"
                    />
                  </span>
                  <span id="product-tour-3" className="md:mr-auto">
                    <button
                      type="submit"
                      className={`rounded-xl text-white px-3.5 py-3.5 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:opacity-75 w-22 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-red-500 hover:to-orange-400`}
                    >
                      {isSubmitting ? (
                        <svg
                          className="animate-spin mx-auto h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Generate"
                      )}
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </main>

        <Toaster position="bottom-right" theme="light" richColors={true} />
      </div>
    </>
  );
}

export default NewChat;
