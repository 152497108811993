import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import { MockEditor } from "@/pages/MockEditor";

type PasteCodeModalProps = {
  pasteCodePrompt: string;
  setPasteCodePrompt: (value: string) => void;
};

export const PasteCodeModal = ({
  pasteCodePrompt,
  setPasteCodePrompt,
}: PasteCodeModalProps) => {
  const files = {
    "/index.html": {
      code: pasteCodePrompt,
    },
    "/index.js": {
      code: "",
    },
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="hover:bg-gray-100"
          style={{ position: "relative" }}
        >
          {pasteCodePrompt ? (
            <span
              style={{
                position: "absolute",
                top: "-5px",
                left: "100%",
                transform: "translateX(-50%)",
              }}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="5" r="5" fill="lightgreen" />
              </svg>
            </span>
          ) : null}
          Paste Code
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-white max-w-6xl">
        <DialogHeader>
          <DialogTitle>Paste Code (Optional)</DialogTitle>
          <DialogDescription>
            If you want the AI to use or modify your own pre-existing code, you
            can paste it below.
          </DialogDescription>
        </DialogHeader>

        <SandpackProvider
          theme="auto"
          template="vanilla"
          files={files}
          options={{
            autoReload: true,
            autorun: true,
            initMode: "user-visible",
            visibleFiles: ["/index.html"],
            activeFile: "/index.html",
          }}
          autoSave={"true"}
        >
          <MockEditor setPasteCodePrompt={setPasteCodePrompt} />
        </SandpackProvider>
      </DialogContent>
    </Dialog>
  );
};
