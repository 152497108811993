import React, { createContext, useState, useEffect, ReactNode } from "react";
import supabase from "@/clients/supabase";

type SessionContextProps = {
  session: any | null;
  setSession: React.Dispatch<React.SetStateAction<any | null>>;
  logout: () => void;
};

const SessionContext = createContext<SessionContextProps | undefined>(
  undefined,
);

type SessionProviderProps = {
  children: ReactNode;
};

function SessionProvider({ children }: SessionProviderProps) {
  const [session, setSession] = useState<any | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  async function logout() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log("Logout failed", error);
    } else {
      setSession(null);
    }
  }

  return (
    <SessionContext.Provider value={{ session, setSession, logout }}>
      {children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionProvider };
