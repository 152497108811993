import { Skeleton } from "@radix-ui/themes";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SessionContext } from "@/contexts/SessionContext";
import axios from "axios";
import {
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
} from "@codesandbox/sandpack-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import { CircleDashedIcon, GitForkIcon, ListIcon } from "lucide-react";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import { LoginModal } from "@/pages/auth/LoginModal";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const tailwindCDN = "https://cdn.tailwindcss.com";
const alpineCDN = "https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js";

function DashboardRender() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // ** ReadMessages **
  const [messages, setMessages] = useState<any[]>([]);
  const [slideoverVisible, setSlideoverVisible] = useState<boolean>(false);

  async function getMessages(chatId: string) {
    if (!chatId) return;
    const getMessagesUrl = `${BACKEND_URL}/api/chats/${chatId}/messages`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.get(getMessagesUrl, { headers });
      const messagesResponse = response.data || [];

      if (messagesResponse.length === 0) {
        navigate("/dashboard/chats"); // Redirect if no messages found
      }
      return messagesResponse;
    } catch (error) {
      console.log(error);
      return []; // Return an empty array in case of an error
    }
  }

  // ** ReadMessage **
  const [messageIndex, setMessageIndex] = useState<number>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [files, setFiles] = useState<any>(null);

  async function initializeData(chatId: string) {
    if (chatId) {
      const messagesResponse = await getMessages(chatId);
      setMessages(messagesResponse);
      if (messagesResponse.length > 0) {
        const version = parseInt(searchParams.get("version"));
        const lastMessageIndex = messagesResponse.length - 1;

        if (version && version <= messages.length) {
          setMessageIndex(version - 1);
        } else {
          setMessageIndex(lastMessageIndex);
        }

        setMessageIndex(lastMessageIndex);
        setFiles({
          "/index.html": {
            code: messagesResponse[lastMessageIndex].full_text,
          },
          "/index.js": {
            code: "",
          },
        });
      }
    }
    setInitialized(true);
  }

  // 2 -> 4 (2)
  useEffect(() => {
    if (!initialized) {
      initializeData(chatId);
    }
  }, []);

  async function getSignedUrl(messageId: string, chatId: string) {
    const url = `${BACKEND_URL}/api/chats/${chatId}/messages/signedurl`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.post(url, { id: messageId }, { headers });
      const signedUrl = response.data;
      return signedUrl;
    } catch (error) {
      console.error(error);
    }
  }

  const [retrievedSignedURL, setRetrievedSignedURL] = useState(false);

  async function getSignedUrls() {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].screenshot) {
        continue; // Skip if screenshot is already present
      }
      const signedUrl = await getSignedUrl(messages[i].id, chatId);
      const newArray = [...messages];
      newArray[i].screenshot = signedUrl;
      setMessages(newArray);
    }
    setRetrievedSignedURL(true);
  }

  // 4 -> 8 (always 4)
  useEffect(() => {
    if (initialized && messages.length > 0 && !retrievedSignedURL) {
      getSignedUrls();
    }
  }, [initialized]);

  function handleMessageClick(id: string) {
    const index = messages.findIndex((message) => message.id === id);
    setMessageIndex(index);
    setFiles({
      "/index.html": {
        code: messages[index].full_text,
      },
      "/index.js": {
        code: "",
      },
    });
  }

  // const [shareNotification, setShareNotification] = useState(false);

  // function shareToClipboard() {
  //   const url = `${window.location.origin}/render/${chatId}?version=${messages.length - messageIndex}`;
  //   navigator.clipboard.writeText(url);
  //   setShareNotification(true);

  //   const timer = setTimeout(() => {
  //     setShareNotification(false);
  //   }, 700);

  //   return () => clearTimeout(timer);
  // };

  const [reloadedData, setReloadedData] = useState(false);

  async function reloadData() {
    const messagesResponse = await getMessages(chatId);
    if (messagesResponse.length > 0) {
      setMessages(messagesResponse);
      setMessageIndex(messagesResponse.length - 1);
      getSignedUrls();
    } else {
      navigate("/dashboard/chats");
    }
    setReloadedData(true);
  }

  useEffect(() => {
    if (initialized && chatId && !reloadedData) {
      reloadData();
    }
  }, [chatId]);

  // Fork
  const [isForking, setIsForking] = useState(false);
  async function forkChat() {
    setIsForking(true);

    // If not logged in, send a toast notification and exit the function
    if (!session || !session.access_token) {
      setIsForking(false);

      toast.warning(
        <>
          <div data-content="">
            <div data-title="" className="">
              You must be logged in to fork a project.
            </div>
          </div>
          <LoginModal>
            <Button
              variant="outline"
              className="px-6 py-1 text-xs border-0 ml-auto h-6 w-7 bg-white text-black font-normal"
              style={{ position: "relative" }}
            >
              Login
            </Button>
          </LoginModal>
        </>,
      );
      return;
    }

    // If user is logged in, then fork the chat
    const forkChatUrl = `${BACKEND_URL}/api/chats/${chatId}/fork`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.post(forkChatUrl, {}, { headers });
      const forkedChatId = response.data;

      setIsForking(false);
      toast.success("Successfully forked", {
        action: {
          label: "View Fork",
          onClick: () => {
            navigate(`/dashboard/chats/${forkedChatId}`);
          },
        },
      });
    } catch (error) {
      console.error(error);
      setIsForking(false);
      toast.error("Error while forking");
    }
  }

  return (
    <>
      {initialized ? (
        <SandpackProvider
          {...(files && { files })}
          theme="auto"
          template="vanilla"
          options={{
            externalResources: [tailwindCDN, alpineCDN],
            autoReload: true,
            autorun: true,
            initMode: "lazy",
            bundlerTimeOut: 1_800_0000,
            visibleFiles: ["/index.html"],
            activeFile: "/index.html",
          }}
        >
          <SandpackLayout>
            <SandpackPreview
              showOpenInCodeSandbox={false}
              showRefreshButton={false}
              style={{
                height: "99.83vh",
              }}
              actionsChildren={
                <>
                  {/* Do not flip this conditional around. This way of doing things prevents it from throwing an error if not logged in. */}
                  {!session || !session.access_token ? (
                    <LoginModal>
                      <button
                        type="button"
                        className="z-30 flex items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                        style={{
                          fontSize: "0.875rem",
                        }}
                      >
                        <span>Login</span>
                      </button>
                    </LoginModal>
                  ) : (
                    <button
                      type="button"
                      className="z-30 flex items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                      style={{
                        fontSize: "0.875rem",
                      }}
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      <span>Dashboard</span>
                    </button>
                  )}

                  {/* <button */}
                  {/*   type="button" */}
                  {/*   className="z-30 flex w-[7.5rem] px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white" */}
                  {/*   style={{ */}
                  {/*     fontSize: "0.875rem", */}
                  {/*   }} */}
                  {/*   onClick={shareToClipboard} */}
                  {/* > */}
                  {/*   {shareNotification ? ( */}
                  {/*     <> */}
                  {/*       <CopyCheckIcon height={16} /> */}
                  {/*       <span>Copied!</span> */}
                  {/*     </> */}
                  {/*   ) : ( */}
                  {/*     <> */}
                  {/*       <CopyIcon height={16} /> */}
                  {/*       <span>Share Link</span> */}
                  {/*     </> */}
                  {/*   )} */}
                  {/* </button> */}

                  <button
                    type="button"
                    className="z-30 flex w-20 items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                    style={{
                      fontSize: "0.875rem",
                    }}
                    onClick={forkChat}
                  >
                    {isForking ? (
                      <>
                        <CircleDashedIcon
                          className="animate-spin"
                          height={16}
                        />
                      </>
                    ) : (
                      <>
                        <GitForkIcon height={16} />
                        <span>Fork</span>
                      </>
                    )}
                  </button>

                  <button
                    type="button"
                    className="z-30 flex items-center justify-center px-3 py-2 space-x-1 text-xs font-medium border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-200 bg-white"
                    style={{
                      fontSize: "0.875rem",
                    }}
                    onClick={() => setSlideoverVisible(true)}
                  >
                    <ListIcon height={16} />
                    <span>History</span>
                  </button>
                </>
              }
            />
          </SandpackLayout>
        </SandpackProvider>
      ) : (
        <div className="flex h-screen items-center">
          <div className="mx-auto flex flex-col space-y-3">
            <Skeleton className="h-[125px] w-[250px] bg-gray-100 rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="rounded-md h-4 w-[250px] bg-gray-100" />
              <Skeleton className="rounded-md h-4 w-[200px] bg-gray-100" />
            </div>
          </div>
        </div>
      )}
      <div className="relative z-50 w-auto h-auto">
        {slideoverVisible && (
          <div className="relative z-[99] fixed inset-0 overflow-auto font-mono">
            <div
              className="fixed inset-0 bg-black bg-opacity-10"
              onClick={() => setSlideoverVisible(false)}
            ></div>
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <div
                    className="w-screen max-w-md"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col h-full py-5 overflow-y-scroll bg-white border-l shadow-lg border-neutral-100/70">
                      <div className="px-4 sm:px-5">
                        <div className="flex items-start justify-between pb-1">
                          <h2 className="text-xl font-semibold leading-6 text-gray-900">
                            History
                          </h2>
                          <div className="flex items-center h-auto ml-3">
                            <button
                              onClick={() => setSlideoverVisible(false)}
                              className="absolute top-0 right-0 z-30 flex items-center justify-center px-3 py-2 mt-4 mr-5 space-x-1 text-xs font-medium uppercase border rounded-md border-neutral-200 text-neutral-600 hover:bg-neutral-100 bg-white"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                              <span>Close</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      {messages && messages.length > 0 && slideoverVisible && (
                        <div className="relative flex-1 px-4 mt-5">
                          <div className="absolute inset-0 px-4 px-7">
                            <div className="relative h-full rounded-md">
                              <div className="mt-8">
                                <div className="flow-root">
                                  <ul role="list" className="-my-6 ">
                                    {messages.map((message, index) => (
                                      <div key={index} className="mb-4">
                                        <TooltipProvider delayDuration={400}>
                                          <Tooltip>
                                            <TooltipTrigger className="cursor-default">
                                              <span
                                                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset
                                              ${index === messageIndex ? "bg-orange-50 ring-orange-400/50 ring-1 ring-inset" : "bg-gray-100 ring-gray-300"}
                                            `}
                                              >
                                                Version{" "}
                                                {messages.length - index}.{" "}
                                                {message.prompt.length > 30
                                                  ? `${message.prompt.slice(0, 30)}...`
                                                  : message.prompt}
                                              </span>
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-white">
                                              <p>{message.prompt}</p>
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>

                                        <li
                                          className={`my-1 flex border-2 rounded-lg 
                                      ${index === messageIndex ? "border-orange-200" : "hover:border-gray-400"}`}
                                        >
                                          <button
                                            className={`w-full flex justify-center items-center transition duration-150 ease-in-ease-out motion-safe text-neutral-700`}
                                            disabled={index === messageIndex}
                                            onClick={() =>
                                              handleMessageClick(message.id)
                                            }
                                          >
                                            {message.screenshot ? (
                                              <img
                                                className="object-cover object-center w-[388px] h-[216px] mx-auto rounded-lg"
                                                src={message.screenshot}
                                                alt="Preview Image"
                                              />
                                            ) : (
                                              <Skeleton className="object-cover object-center w-full h-[216px] mx-auto rounded-lg" />
                                            )}
                                          </button>
                                        </li>
                                      </div>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Toaster
        position="top-right"
        theme="light"
        expand={true}
        closeButton={true}
      />
    </>
  );
}

export default DashboardRender;
