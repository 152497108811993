import React, { useState, useContext, useEffect } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "../ui/button";
import { MessageCircleIcon } from "lucide-react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

type FeedbackProps = {
  isVisible?: boolean;
  message?: any;
};

function Feedback({ isVisible, message }: FeedbackProps) {
  // Auth
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { chatId } = useParams();

  // Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleModalClose() {
    setModalOpen(false);
    setIsSubmitted(false);
  }

  // Handle escape key press to close the modal
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setModalOpen(false);
        setIsSubmitted(false);
      }
    };

    window.addEventListener("keydown", handleEscape);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);

  function handleFeedback(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const content = event.target["feedback"].value;

    const feedbackUrl = `${BACKEND_URL}/api/feedback`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    const feedbackData = {
      name: "general",
      content: content,
    };

    if (message && message?.id) {
      feedbackData["message_id"] = message.id;
    } else if (chatId) {
      feedbackData["chat_id"] = chatId;
    }

    axios
      .post(feedbackUrl, feedbackData, { headers: headers })
      .then((response) => {
        console.log(response);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {isVisible && (
        <>
          <Button
            variant={"outline"}
            onClick={() => setModalOpen(true)}
            className="tracking-wide bg-orange-100 hover:bg-orange-200"
          >
            <MessageCircleIcon /> &nbsp; Send Feedback
          </Button>

          {modalOpen && (
            <div className="fixed inset-0 z-[99] flex items-center justify-center">
              <div
                onClick={() => handleModalClose()}
                className="absolute inset-0 bg-black bg-opacity-40 transition-opacity duration-300"
              ></div>

              <div className="relative w-full py-6 bg-white px-7 sm:max-w-lg sm:rounded-lg transition-transform duration-300 transform ease-out">
                <div className="flex items-center justify-between pb-2">
                  <button
                    onClick={() => handleModalClose()}
                    className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 mt-5 mr-5 text-gray-600 rounded-full hover:text-gray-800 hover:bg-gray-50"
                  >
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                {isSubmitted ? (
                  <>
                    <div className="flex flex-col space-y-1.5 p-6">
                      <h3 className="text-lg font-semibold leading-none tracking-tight">
                        Thanks for the feedback!
                      </h3>
                      <p className="text-sm text-neutral-500"></p>
                    </div>

                    <div className="p-6 pt-0 space-y-2">
                      <div className="space-y-1">
                        <p className="text-sm text-neutral-500">
                          Thanks for letting me know!
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col space-y-1.5 p-6">
                      <h3 className="text-lg font-semibold leading-none tracking-tight">
                        What can we improve?
                      </h3>
                    </div>

                    <form onSubmit={handleFeedback}>
                      <div className="p-6 pt-0 space-y-2">
                        <div className="space-y-1">
                          <textarea
                            id="feedback"
                            required={true}
                            rows={8}
                            className="flex w-full px-3 py-2 text-sm bg-white border rounded-md peer border-neutral-300 ring-offset-background placeholder:text-neutral-400 placeholder:text-lg focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                          />
                        </div>
                      </div>

                      <div className="flex items-center p-6 pt-0">
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center px-4 py-3 text-sm font-medium tracking-wide text-white transition-colors duration-200 rounded-md bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:ring-offset-2 focus:ring-neutral-900 focus:shadow-outline focus:outline-none"
                        >
                          Send Feedback
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Feedback;
