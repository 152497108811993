import { Fragment, useContext, useEffect, useState } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { toast } from "sonner";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const stripeCustomerPortalLink = import.meta.env
  .VITE_STRIPE_CUSTOMER_PORTAL_LINK;
const stripePaymentLinkId = import.meta.env.VITE_STRIPE_PAYMENT_LINK_ID;
const paymentLink = `https://buy.stripe.com/${stripePaymentLinkId}`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AccountButton() {
  // Auth
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { logout } = sessionContext;
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const [customerId, setCustomerId] = useState<string | null>(null);

  // Expecting:
  //  Request:  GET /api/customers
  //  Response: {"id": "cus_123", "status": "active"}
  async function retrieveCustomer() {
    const retrieveCustomerUrl = `${BACKEND_URL}/api/customers`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    try {
      const response = await axios.get(retrieveCustomerUrl, { headers });
      if (response.data.length > 0 && response.data[0].stripe_customer_id) {
        setCustomerId(response.data[0].stripe_customer_id);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error: " + error.message);
    }
  }

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (session && !initialized) {
      retrieveCustomer();
      setInitialized(true);
    }
  }, []);

  function getSubscriptionLink() {
    if (customerId && session?.user?.email) {
      return `${stripeCustomerPortalLink}?prefilled_email=${session.user.email}`;
    } else if (session?.user) {
      return `${paymentLink}?client_reference_id=${session.user.id}&prefilled_email=${session.user.email}`;
    }
    return "#"; // Fallback link
  }

  return (
    <>
      <Menu as="div" className="relative text-top">
        <div>
          <Menu.Button className="group relative flex w-full justify-center text-sm text-gray-500 bg-gray-100 hover:bg-gray-200 py-4 border-y border-gray-300">
            <span className="text-gray-800 px-5 py-2 rounded-lg">
              {session.user.email}
            </span>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute inset-x-0 bottom-0 z-10 bottom-full w-56 mx-2 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={getSubscriptionLink()}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm",
                    )}
                  >
                    Subscription
                  </a>
                )}
              </Menu.Item>
              <hr />

              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    onClick={handleLogout}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm",
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
