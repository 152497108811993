import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import App from "@/App";
import "@/styles/utils.css";
import "@radix-ui/themes/styles.css";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

const ENVIRONMENT = import.meta.env.ENVIRONMENT;
const SENTRY_DSN = import.meta.env.SENTRY_DSN;
const replaysSessionSampleRate = ENVIRONMENT === "PRODUCTION" ? 0.1 : 1.0;

if (ENVIRONMENT === "PRODUCTION") {
  const PUBLIC_POSTHOG_KEY = import.meta.env.VITE_PUBLIC_POSTHOG_KEY;
  const PUBLIC_POSTHOG_HOST = import.meta.env.VITE_PUBLIC_POSTHOG_HOST;
  posthog.init(PUBLIC_POSTHOG_KEY, { api_host: PUBLIC_POSTHOG_HOST });
}

Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend(event, hint) {
    // eslint-disable-line
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
    new Sentry.Feedback({
      showBranding: false,
      colorScheme: "system",
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/auto-templater-frontend-staging\.onrender\.com/,
    /^https:\/\/makedraft\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  environment: ENVIRONMENT,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      {ENVIRONMENT === "PRODUCTION" ? (
        <PostHogProvider client={posthog}>
          <App />
        </PostHogProvider>
      ) : (
        <App />
      )}
    </BrowserRouter>
  </React.StrictMode>,
);
