import { SandpackLayout, useActiveCode } from "@codesandbox/sandpack-react";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { SandpackMonacoEditor } from "@/components/editor/SandpackMonacoEditor";

type MockEditorProps = {
  setPasteCodePrompt: (value: string) => void;
};

function MockEditor({ setPasteCodePrompt }: MockEditorProps) {
  const { code } = useActiveCode();

  return (
    <>
      <SandpackLayout>
        <SandpackMonacoEditor
          style={{
            height: "50vh",
          }}
        />
      </SandpackLayout>
      <div className="mt-6">
        <DialogFooter>
          <DialogClose>
            <Button
              className="font-semibold text-gray-800 hover:bg-gray-100"
              type="submit"
            >
              Cancel
            </Button>
          </DialogClose>
          <DialogClose>
            <Button
              variant="outline"
              className="bg-green-300 hover:bg-green-400/80 text-gray-900"
              onClick={() => setPasteCodePrompt(code)}
            >
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </div>
    </>
  );
}

export { MockEditor };
