import {
  useActiveCode,
  SandpackStack,
  FileTabs,
  useSandpack,
} from "@codesandbox/sandpack-react";
import Editor from "@monaco-editor/react";
import { getLanguageOfFile } from "@/utils/language";
import { useEffect, useRef } from "react";

type SandpackMonacoEditorProps = {
  className?: string;
  style?: React.CSSProperties;
  onSelectionChange?: (selectedText: string, selectedRange: any) => void;
};

function SandpackMonacoEditor({
  className,
  style,
  onSelectionChange,
}: SandpackMonacoEditorProps) {
  const { code, updateCode } = useActiveCode();
  const { sandpack } = useSandpack();
  const editorRef = useRef<any>(null);
  const language = getLanguageOfFile(sandpack.activeFile);

  function handleEditorMount(editor: any) {
    editorRef.current = editor;
  }

  useEffect(() => {
    const editorInstance = editorRef.current;

    if (editorInstance) {
      const selectionChangeListener = editorInstance.onDidChangeCursorSelection(
        () => {
          const selection = editorInstance.getSelection();
          const text = editorInstance.getModel().getValueInRange(selection);
          if (onSelectionChange) {
            onSelectionChange(text, selection);
          }
        },
      );

      return () => {
        selectionChangeListener.dispose();
      };
    }
  }, [onSelectionChange]);

  // This absolutely NEEDS to be separate from the above useEffect
  // #1: If you put this in the same useEffect as above, the editor will Escape when you press any key
  // This updates the code in the editor when the code changes from API calls
  useEffect(() => {
    if (
      editorRef.current &&
      code !== undefined &&
      code !== editorRef.current.getValue()
    ) {
      editorRef.current.setValue(code);
    }
  }, [code]);

  return (
    <SandpackStack className={className} style={{ ...style }}>
      <FileTabs />
      <div style={{ flex: 1, paddingTop: 8, background: "#1e1e1e" }}>
        <Editor
          width="100%"
          height="100%"
          language={language}
          theme="vs-dark"
          key={sandpack.activeFile}
          defaultValue={code}
          onChange={(value) => updateCode(value || "")}
          onMount={handleEditorMount}
          options={{
            minimap: {
              enabled: false,
            },
          }}
        />
      </div>
    </SandpackStack>
  );
}

export { SandpackMonacoEditor };
