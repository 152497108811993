import LZString from "lz-string";

export const getCodeFromHash = () => {
  if (typeof location === "undefined") return undefined;
  if (location.href.endsWith(location.pathname)) return undefined;

  const hash = location.hash.slice(1);

  if (!hash) return "";

  if (hash.includes("access_token=")) return "";

  const code =
    LZString.decompressFromEncodedURIComponent(hash) ||
    decodeURIComponent(hash);

  // if code contains 'access_token=' then return ''
  if (code.includes("access_token=")) return "";

  return code;
};

export const getHashFromCode = (code: string) => {
  const compressed = LZString.compressToEncodedURIComponent(code);
  const hash = encodeURIComponent(compressed);
  return hash;
};

export const updateHash = (code: string) => {
  if (typeof history === "undefined") return;

  const hash = code ? LZString.compressToEncodedURIComponent(code) : "";
  history.replaceState(history.state, "", `#${hash}`);
};

export const getHash = () => {
  if (typeof location === "undefined") return undefined;
  if (location.href.endsWith(location.pathname)) return undefined;

  const hash = location.hash.slice(1);

  if (!hash) return "";

  return hash;
};
