import React, { useState, useContext, useEffect } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ThumbsDown, ThumbsUp } from "lucide-react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

type ResponseFeedbackProps = {
  message?: any;
};

function ResponseFeedback({ message }: ResponseFeedbackProps) {
  // Auth
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { chatId } = useParams();

  // Modal
  const [thumbDownModalOpen, setThumbDownModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedThumb, setSelectedThumb] = useState<string>("");

  function handleModalClose() {
    setThumbDownModalOpen(false);
    setIsSubmitted(false);
  }

  // Handle escape key press to close the modal
  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setThumbDownModalOpen(false);
        setIsSubmitted(false);
      }
    }

    window.addEventListener("keydown", handleEscape);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);

  async function handleFeedback(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const feedback = event.target["feedback"].value;
    const checkboxHarmful = event.target["checkbox-harmful"].checked;
    const checkboxUntrue = event.target["checkbox-untrue"].checked;
    const checkboxUnhelpful = event.target["checkbox-unhelpful"].checked;

    const feedbackUrl = `${BACKEND_URL}/api/feedback`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    const feedbackData = {
      name: "general",
      content: feedback,
    };

    if (checkboxHarmful) {
      feedbackData["is_harmful"] = true;
    } else {
      feedbackData["is_harmful"] = false;
    }

    if (checkboxUntrue) {
      feedbackData["is_untrue"] = true;
    } else {
      feedbackData["is_untrue"] = false;
    }

    if (checkboxUnhelpful) {
      feedbackData["is_unhelpful"] = true;
    } else {
      feedbackData["is_unhelpful"] = false;
    }

    if (message && message?.id) {
      feedbackData["message_id"] = message.id;
    }

    try {
      await axios.post(feedbackUrl, feedbackData, {
        headers: headers,
      });
      setIsSubmitted(true);
      setSelectedThumb("thumbs-down");
    } catch (error) {
      console.log(error);
    }
  }

  async function handleThumbsUpDown(isThumbsUp: boolean) {
    const feedbackUrl = `${BACKEND_URL}/api/feedback`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    let content: string;

    if (isThumbsUp) {
      content = "thumbs-up";
    } else {
      content = "thumbs-down";
      setThumbDownModalOpen(true);
    }

    // Empty string evaluates to false in JS
    if (!selectedThumb) {
      setSelectedThumb(content); // UI Update
    }

    const feedbackData = {
      name: "thumbs",
      content: content,
    };

    if (message && message?.id) {
      feedbackData["message_id"] = message.id;
    } else if (chatId) {
      feedbackData["chat_id"] = chatId;
    }

    try {
      await axios.post(feedbackUrl, feedbackData, {
        headers: headers,
      });
    } catch (error) {
      console.log(error);
      setSelectedThumb("");
    }
  }

  return (
    <>
      <div className="flex overflow-hidden bg-neutral-100 border divide-x rounded-lg rtl:flex-row-reverse ">
        {selectedThumb ? (
          <>
            <button
              disabled={true}
              className={`
                px-3 py-2 text-sm font-medium text-neutral-700 transition-colors duration-200 sm:text-base
                ${selectedThumb === "thumbs-up" ? "bg-neutral-200" : ""}
              `}
            >
              <ThumbsUp size={18} />
            </button>

            <button
              disabled={true}
              className={`
                px-3 py-2 text-sm font-medium text-neutral-700 transition-colors duration-200 sm:text-base
                ${selectedThumb === "thumbs-down" ? "bg-neutral-200" : ""}
              `}
            >
              <ThumbsDown size={18} />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleThumbsUpDown(true)}
              className="px-3 py-2 text-sm font-medium text-neutral-700 hover:text-neutral-600 hover:bg-neutral-200 transition-colors duration-200 sm:text-base"
            >
              <ThumbsUp size={18} />
            </button>

            <button
              onClick={() => handleThumbsUpDown(false)}
              className="px-3 py-2 text-sm font-medium text-neutral-700 hover:text-neutral-600 hover:bg-neutral-200 transition-colors duration-200 sm:text-base"
            >
              <ThumbsDown size={18} />
            </button>
          </>
        )}
      </div>

      {thumbDownModalOpen && (
        <div className="fixed inset-0 z-[99] flex items-center justify-center">
          <div
            onClick={() => handleModalClose()}
            className="absolute inset-0 bg-black bg-opacity-40 transition-opacity duration-300"
          ></div>

          <div className="relative w-full py-6 text-neutral-700 bg-white px-7 sm:max-w-lg sm:rounded-lg transition-transform duration-300 transform ease-out">
            <div className="flex items-center justify-between pb-2">
              <button
                onClick={() => handleModalClose()}
                className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 mt-5 mr-5 text-gray-600 rounded-full hover:text-gray-800 hover:bg-gray-50"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {isSubmitted ? (
              <>
                <div className="flex flex-col space-y-1.5 p-6">
                  <h3 className="text-lg font-semibold leading-none tracking-tight">
                    Thanks for the feedback!
                  </h3>
                  <p className="text-sm text-neutral-500"></p>
                </div>

                <div className="p-6 pt-0 space-y-2">
                  <div className="space-y-1">
                    <p className="text-sm text-neutral-500">
                      Thanks for letting me know!
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col space-y-1.5 p-6">
                  <h3 className="text-lg font-semibold leading-none tracking-tight">
                    Provide Feedback
                  </h3>
                  <p className="text-sm text-neutral-500">
                    What can we do to improve?
                  </p>
                </div>

                <form onSubmit={handleFeedback}>
                  <div className="p-6 pt-0 space-y-2">
                    <div className="space-y-2">
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Description
                      </label>
                      <textarea
                        id="feedback"
                        required={true}
                        rows={4}
                        placeholder="What was the issue with the response? How could it be improved?"
                        className="flex w-full px-3 py-2 text-sm bg-white border rounded-md peer border-neutral-300 ring-offset-background placeholder:text-neutral-400 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:cursor-not-allowed disabled:opacity-50"
                      />
                      <div>
                        <input
                          name="checkbox-harmful"
                          id="checkbox-harmful"
                          type="checkbox"
                          className="hidden peer"
                        />
                        <label
                          htmlFor="checkbox-harmful"
                          className="peer-checked:[&_svg]:scale-100 text-sm font-medium text-neutral-600 peer-checked:text-blue-600 [&_svg]:scale-0 peer-checked:[&_.checkbox-harmful]:border-blue-500 peer-checked:[&_.checkbox-harmful]:bg-blue-500 select-none flex items-center space-x-2"
                        >
                          <span className="flex items-center justify-center w-5 h-5 border-2 rounded checkbox-harmful text-neutral-900">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="3"
                              stroke="currentColor"
                              className="w-3 h-3 text-white duration-300 ease-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                          <span>This is harmful/unsafe</span>
                        </label>
                      </div>

                      <div>
                        <input
                          name="checkbox-untrue"
                          id="checkbox-untrue"
                          type="checkbox"
                          className="hidden peer"
                        />
                        <label
                          htmlFor="checkbox-untrue"
                          className="peer-checked:[&_svg]:scale-100 text-sm font-medium text-neutral-600 peer-checked:text-blue-600 [&_svg]:scale-0 peer-checked:[&_.checkbox-untrue]:border-blue-500 peer-checked:[&_.checkbox-untrue]:bg-blue-500 select-none flex items-center space-x-2"
                        >
                          <span className="flex items-center justify-center w-5 h-5 border-2 rounded checkbox-untrue text-neutral-900">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="3"
                              stroke="currentColor"
                              className="w-3 h-3 text-white duration-300 ease-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                          <span>This isn&apos;t true</span>
                        </label>
                      </div>

                      <div>
                        <input
                          name="checkbox-unhelpful"
                          id="checkbox-unhelpful"
                          type="checkbox"
                          className="hidden peer"
                        />
                        <label
                          htmlFor="checkbox-unhelpful"
                          className="peer-checked:[&_svg]:scale-100 text-sm font-medium text-neutral-600 peer-checked:text-blue-600 [&_svg]:scale-0 peer-checked:[&_.checkbox-unhelpful]:border-blue-500 peer-checked:[&_.checkbox-unhelpful]:bg-blue-500 select-none flex items-center space-x-2"
                        >
                          <span className="flex items-center justify-center w-5 h-5 border-2 rounded checkbox-unhelpful text-neutral-900">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="3"
                              stroke="currentColor"
                              className="w-3 h-3 text-white duration-300 ease-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                          <span>This isn&apos;t helpful</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center p-6 pt-0">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-4 py-3 text-sm font-medium tracking-wide text-white transition-colors duration-200 rounded-md bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:ring-offset-2 focus:ring-neutral-900 focus:shadow-outline focus:outline-none"
                    >
                      Send Feedback
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ResponseFeedback;
