import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import React, { useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "@/clients/supabase";
import { useNavigate } from "react-router-dom";

const frontendUrl = import.meta.env.VITE_FRONTEND_URL;
const redirectAfterEmailConfirmationUrl = frontendUrl + "/dashboard";

interface LoginModalProps {
  children: React.ReactNode;
}

export const LoginModal = ({ children }: LoginModalProps) => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    if (!session) {
      return (
        <Dialog>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>
                <h2 className="text-2xl mb-2">Makedraft</h2>
              </DialogTitle>

              <DialogDescription>
                <Auth
                  supabaseClient={supabase}
                  appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: "orange",
                          brandAccent: "darkorange",
                        },
                      },
                    },
                  }}
                  providers={["github"]}
                  redirectTo={redirectAfterEmailConfirmationUrl}
                  view="sign_in"
                />
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      );
    }
  }
};
