import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SessionContext } from "@/contexts/SessionContext";
import axios from "axios";
import {
  SandpackLayout,
  SandpackPreview,
  useActiveCode,
} from "@codesandbox/sandpack-react";
import { SandpackMonacoEditor } from "@/components/editor/SandpackMonacoEditor";
import * as monaco from "monaco-editor";
import { Loader2Icon, Maximize2Icon, Minimize2Icon } from "lucide-react";
import ResponseFeedback from "@/components/editor/ResponseFeedback";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import { IframePreview } from "@/components/editor/IframePreview";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
const stripePaymentLinkId = import.meta.env.VITE_STRIPE_PAYMENT_LINK_ID;
const paymentLink = `https://buy.stripe.com/${stripePaymentLinkId}`;

type EditMessageProps = {
  messageIndex: number;
  setMessageIndex: (index: number) => void;
  messages: Array<any>;
  setMessages: (array: Array<any>) => void;
  visualEditorEnabled: boolean;
};

function EditMessage({
  messageIndex,
  setMessageIndex,
  messages,
  setMessages,
  visualEditorEnabled,
}: EditMessageProps) {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;
  const { chatId } = useParams();
  const { code, updateCode } = useActiveCode();

  const message = messages[messageIndex];

  useEffect(() => {
    if (message && message.full_text) {
      updateCode(message.full_text);
    }
  }, [messageIndex]);

  // Editor: Selected Text
  const [selectedText, setSelectedText] = useState<string>("");
  const [selectedRange, setSelectedRange] = useState<monaco.Selection>();
  const handleSelectionChange = (text: string, selection: monaco.Selection) => {
    setSelectedText(text);
    setSelectedRange(selection);
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [iframeCode, setIframeCode] = useState<string>("");

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const prompt = event.target["prompt"].value;
    const reviseUrl = `${BACKEND_URL}/api/chats/${chatId}/messages/revise`;
    const converseUrl = `${BACKEND_URL}/api/chats/${chatId}/messages/converse`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };

    let fullCode: string;

    if (iframeCode) {
      fullCode = iframeCode;
    } else {
      fullCode = code;
    }

    setIsSubmitting(true);

    if (selectedText.length > 0) {
      let reviseData = {
        prompt: prompt,
        text: selectedText,
        full_text: fullCode,
        start_line: selectedRange.startLineNumber,
        start_column: selectedRange.startColumn,
        end_line: selectedRange.endLineNumber,
        end_column: selectedRange.endColumn,
      };

      // If the message has an ID, it means it's already been saved to the
      // database. In which case we need to send the ID to the backend so it can
      // update the existing record, instead of creating a new one.
      // It would also delete any messages that were created after the current
      // message.
      if (message && message.id) {
        reviseData = {
          ...reviseData,
          id: message.id,
        };
      }

      try {
        const response = await axios.post(
          reviseUrl,
          { ...reviseData },
          { headers },
        );
        const messageResponse = response.data;
        updateCode(messageResponse.full_text);

        if (messageIndex == 0) {
          const newMessages = [messageResponse, ...messages];
          setMessages(newMessages);
        } else if (messageIndex > 0 && messageIndex < messages.length) {
          // slice(from_including, to_excluding)
          const newMessages = [
            messageResponse,
            ...messages.slice(messageIndex, messages.length),
          ];
          setMessages(newMessages);
          setMessageIndex(0);
        } else {
          toast.error("Error: Invalid index");
        }
      } catch (error) {
        if (error.response.status === 402) {
          toast("Exceeded subscription monthly usage limit", {
            action: {
              label: "Subscription",
              onClick: () => {
                window.open(
                  `${paymentLink}?client_reference_id=${session.user.id}&prefilled_email=${session.user.email}`,
                );
              },
            },
          });
        } else {
          console.log(error);
          toast.error("Error: " + error.message);
        }
      }
    } else {
      let converseData = {
        prompt: prompt,
        full_text: fullCode,
      };

      // If the message has an ID, it means it's already been saved to the
      // database. In which case we need to send the ID to the backend so it can
      // update the existing record, instead of creating a new one.
      // It would also delete any messages that were created after the current
      // message.
      if (message && message.id) {
        converseData = { ...converseData, id: message.id };
      }

      try {
        const response = await axios.post(
          converseUrl,
          { ...converseData },
          { headers },
        );
        const messageResponse = response.data;
        updateCode(messageResponse.full_text);

        if (messageIndex == 0) {
          const newMessages = [messageResponse, ...messages];
          setMessages(newMessages);
        } else if (messageIndex > 0 && messageIndex < messages.length) {
          // slice(from_including, to_excluding)
          const newMessages = [
            messageResponse,
            ...messages.slice(messageIndex, messages.length),
          ];
          setMessages(newMessages);
          setMessageIndex(0);
        } else {
          toast.error("Error: Invalid index");
        }
      } catch (error) {
        if (error.response.status === 402) {
          toast("Exceeded subscription monthly usage limit", {
            action: {
              label: "Subscription",
              onClick: () => {
                window.open(
                  `${paymentLink}?client_reference_id=${session.user.id}&prefilled_email=${session.user.email}`,
                );
              },
            },
          });
        } else {
          console.log(error);
          toast.error("Error: " + error.message);
        }
      }
    }

    setIsSubmitting(false);
  }

  const [isSaving, setIsSaving] = useState(false);

  async function handleSaveMessage() {
    const saveUrl = `${BACKEND_URL}/api/chats/${chatId}/messages`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session.access_token,
    };
    let saveData = { full_text: code };
    if (message && message.id) {
      saveData = { ...saveData, id: message.id };
    }

    try {
      setIsSaving(true);
      const response = await axios.post(saveUrl, { ...saveData }, { headers });
      const messageResponse = response.data;
      updateCode(messageResponse.full_text);

      if (messageIndex == 0) {
        const newMessages = [messageResponse, ...messages];
        setMessages(newMessages);
        setIsSaving(false);
      } else if (messageIndex > 0 && messageIndex < messages.length) {
        // slice(from_including, to_excluding)
        const newMessages = [
          messageResponse,
          ...messages.slice(messageIndex, messages.length),
        ];
        setMessages(newMessages);
        setMessageIndex(0);
        setIsSaving(false);
        toast.success("Message saved.");
      } else {
        setIsSaving(false);
        toast.error("Error: Invalid index");
      }
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      toast.error("Error: " + error.message);
    }
  }

  const [isMaximizedEditor, setMaximizedEditor] = useState<any>(false);

  useEffect(() => {
    if (!visualEditorEnabled) {
      setSelectedText("");
      setSelectedRange(null);
      setIframeCode("");
    }
  }, [visualEditorEnabled]);

  return (
    <>
      <div className="rounded-lg h-fit mb-4">
        {visualEditorEnabled ? (
          <IframePreview
            setSelectedText={setSelectedText}
            setSelectedRange={setSelectedRange}
            setIframeCode={setIframeCode}
          />
        ) : (
          <span></span>
        )}

        <SandpackLayout
          className={`
            mx-auto ${visualEditorEnabled && "invisible w-[0px] h-[0px]"}
          `}
        >
          <span id="product-tour-5"></span>
          <SandpackMonacoEditor
            onSelectionChange={handleSelectionChange}
            style={{
              height: isMaximizedEditor ? "100vh" : "75vh",
            }}
          />

          <SandpackPreview
            style={{ height: isMaximizedEditor ? "100vh" : "75vh" }}
            showOpenInCodeSandbox={false}
            showRefreshButton={true}
            actionsChildren={
              <>
                <span id="product-tour-8">
                  <button
                    type="button"
                    className="sp-icon-standalone sp-c-bxeRRt sp-c-gMfcns sp-c-dEbKhQ sp-button"
                    onClick={() => setMaximizedEditor(!isMaximizedEditor)}
                  >
                    {isMaximizedEditor ? <Minimize2Icon /> : <Maximize2Icon />}
                  </button>
                </span>
              </>
            }
          />
          <span id="product-tour-7"></span>
        </SandpackLayout>

        <div className="flex flex-row mt-4">
          <div className="font-mono w-full max-w-2xl mx-auto">
            <form onSubmit={handleFormSubmit}>
              <div className="flex max-w-2xl gap-x-4 text-sm">
                <input
                  id="prompt"
                  name="prompt"
                  type="text"
                  required
                  className="min-w-0 flex-auto rounded-xl border border-neutral-300 bg-white/5 px-3.5 py-2 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-inset focus:ring-gray-200 sm:leading-6 disabled:bg-gray-100"
                  placeholder="Generate HTML with AI"
                />
                <span id="product-tour-10">
                  <button
                    type="submit"
                    className={`flex-none rounded-xl text-white px-2 py-3 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:opacity-75 w-22 ${
                      selectedText
                        ? "bg-gradient-to-r from-green-400 to-blue-500 hover:from-cyan-500 hover:to-indigo-500"
                        : "bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-red-500 hover:to-orange-400"
                    }`}
                  >
                    {isSubmitting || isSaving ? (
                      <Loader2Icon className="animate-spin text-white h-5 mx-5" />
                    ) : selectedText ? (
                      <span className="mx-5">Edit</span>
                    ) : (
                      <span className="mx-1">Generate</span>
                    )}
                  </button>
                </span>
              </div>
            </form>

            <div className="inline-flex items-center mt-2">
              {isSaving ? (
                <button
                  disabled={true}
                  className="mr-2 px-3 py-1.5 w-16 font-medium bg-neutral-100 rounded-md cursor-pointer border border-neutral-200/60 hover:bg-neutral-200 active:bg-white focus:bg-white focus:outline-none text-neutral-700 hover:text-neutral-600"
                >
                  <svg
                    className="animate-spin mx-auto h-5 w-5 text-neutral-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              ) : (
                <button
                  onClick={handleSaveMessage}
                  className="mr-2 px-3 pt-2.5 pb-2 w-16 font-medium bg-neutral-100 rounded-md cursor-pointer border border-neutral-200/60 hover:bg-neutral-200 active:bg-white focus:bg-white focus:outline-none text-neutral-700 hover:text-neutral-600"
                >
                  <span id="product-tour-6"> Save </span>
                </button>
              )}

              <ResponseFeedback message={message} />
            </div>
          </div>
        </div>
      </div>

      <Toaster
        position="bottom-right"
        theme="light"
        expand={true}
        closeButton={true}
      />
    </>
  );
}

export { EditMessage };
